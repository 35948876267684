import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/d-ford-004.png"
import GetInTouch from "../components/molecules/GetInTouch"

function DFord() {
  return (
    <>
      <Seo
        title="Mobilising people, beyond just vehicles | Craig Walker x Ford"
        image={thumb}
        description="D-ford is the design and innovation arm of the Ford Motor Company. It was set-up to challenge the status quo and provoke imagination."
      />

      <Splash text={<>Mobilising people, beyond just vehicles</>} work={true}>
        <p>
          D-ford is the design and innovation arm of the Ford Motor Company. It
          was set-up to challenge the status quo and provoke imagination. Its
          goal is to drive human progress and meet the evolving needs of people
          through empathy, creativity and design – beyond just vehicles.
        </p>
        <p>
          D-ford has partnered with Craig Walker to explore visions of the
          future, and imagine how Ford might meet their customers in this
          future. Together, by creating and testing fully immersive prototypes,
          we are creating a future where mobility, autonomy and connectivity are
          flexible, memorable, mindful, and most of all, empowering.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage transparent>
          <Image imgName="d-ford-001.png" />
        </WorkImage>

        <Numbers>
          <Number number="150+" text="People interviewed" />
          <Number number={20} text="Collaborative workshops" />
          <Number number="10+" text="Prototypes developed" />
        </Numbers>

        <WorkTextBlock>
          <h2>Supporting global research</h2>
          <p>
            D-ford employs human-centred design to champion the user’s voice.
            They are using a design research methodology to uncover insights
            from people across the globe, to discover hidden opportunities that
            are not focussed solely on driving. Craig Walker is supporting
            D-ford in speaking to existing and potential customers, from
            Thailand to Europe, in a global panel and synthesising insights into
            future-focused provocations and prototypes.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="d-ford-002.png" />
        </WorkImage>

        <WorkTextBlock>
          <h2>
            “If I had asked people what they wanted, they would have said faster
            horses.”
            <p>— Henry Ford</p>
          </h2>
          <p>
            Ford has always worked to anticipate 'what's next? Over the last
            several decades, the automotive industry and its products has
            remained relatively undisrupted - but technological advances and
            connectivity are starting to shift consumer behaviour from car
            ownership to vehicle related services.
          </p>
          <p>
            To remain competitive for the next few decades, Ford will have to
            innovate beyond just making vehicles. To that end, D-ford are
            exploring ways to help human progress and fullfil user’s needs at
            the intersection of work, family and play.
          </p>
        </WorkTextBlock>

        <WorkImage transparent>
          <Image imgName="d-ford-003.png" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Bringing out craft through prototypes</h2>
          <p>
            Using D-Ford’s global insights, Craig Walker created provocative
            prototypes to inform future products and services. We use a ‘build
            to learn’ philosophy, wherein we create several prototypes to spark
            curiosity and expore what is possible. This often stirs more
            questions than answers. We aim to make both mistakes and discoveries
            quickly, in order to push the next iteration forward, and to learn
            more, faster. Making things tangible by sketching, wireframing and
            3D modelling has helped up open new possibilities to refine
            propositions with Ford, narrowing the scope of possibilities, while
            increasing fidelity.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="d-ford-004.png" />
        </WorkImage>
        <GetInTouch dataView="MakeReal" />
        <RelatedWork exclude="d-ford" />
      </ContentHolder>
    </>
  )
}

export default DFord
